import Loader from 'components/Loader'
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
//rutas


const ConfiguracionVentas = lazy(async () => import('./ConfiguracionVentas'))
const NotasCredito = lazy(async () => import('./NotasCredito'))
const MonitorFE = lazy(async () => import('./FE-noAutorizados'))
const MonitorNC = lazy(async () => import('./MonitorNC'))
const SincronizacionResoluciones = lazy(async () => import('./SincronizacionResoluciones'))
const VisorFe = lazy(async () => import('./VisorFe'))


const FacturacionElectronica = ({ match }: any) => {


  return (
    <div className="app-wrapper">
      <Switch>
        <Suspense fallback={<Loader />}>
          <Route path={`${match.url}/configuracion_ventas`} component={ConfiguracionVentas} />
          <Route path={`${match.url}/notas-credito`} component={MonitorNC} />
          <Route path={`${match.url}/monitor`} component={MonitorFE} />
          <Route path={`${match.url}/monitor-nc`} component={MonitorNC} />
          <Route path={`${match.url}/sincronizacion_resoluciones`} component={SincronizacionResoluciones} />
          <Route path={`${match.url}/visor`} component={VisorFe} />
        </Suspense>
      </Switch>
    </div>
  )
}

export default FacturacionElectronica
